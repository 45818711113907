import React from 'react';
import logo from './logo.svg';
import './App.css';

function App() {
  return (
    <div className="App">
      <iframe src='https://suncrochet.framer.website/' title='Iframe' style={{
        height: '100vh',
        width: '100vw',
        overflow: 'hidden'
      }} 
      frameBorder="0"/>
    </div>
  );
}

export default App;
